export default {
  namespaced: true,
  state: {
    swiper: {},
    index: 0
  },
  getters: {
    getSwiper: state => state.swiper,
    getIndex: state => state.index
  },
  mutations: {
    REGISTER(state, { swiper }) {
      state.swiper = swiper;
    },
    SET_INDEX(state, { index }) {
      state.index = index;
    }
  },
  actions: {
    registerSwiper({ state }, swiper) {
      state.swiper = swiper;
    },
    slideTo({ state, commit }, index) {
      state.swiper.slideTo(index);
      commit("nav/CLOSE_NAV", null, { root: true });
    }
  }
};
