export default {
  namespaced: true,
  state: {
    nav: false,
    blend: true,
    links: [
      {
        label: "Patrol",
        path: 1
      },
      {
        label: "Booking",
        path: "/page/booking"
      },
      {
        label: "Mentions légales",
        path: "/page/legal-notice"
      }
    ]
  },
  getters: {
    getNav: state => state.nav,
    getBlend: state => state.blend,
    getLinks: state => state.links
  },
  mutations: {
    OPEN_NAV(state) {
      state.nav = true;
    },
    CLOSE_NAV(state) {
      state.nav = false;
    },
    BLEND_NAV(state, { value }) {
      state.blend = value;
    }
  }
};
